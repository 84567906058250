* {
  font-family: Sky Text, Helvetica, Arial, sans-serif !important;
}

// dependencies
@import '~video.js/dist/video-js.css';

// main
@import 'sky-toolkit-core/tools';

// components
@import 'sky-toolkit-ui/components/typography';
@import 'sky-toolkit-ui/components/buttons';
@import 'sky-toolkit-ui/components/spinner';

// overrides
.btn-no-border {
  border: none;
}

.c-btn {
  display: inline-flex;
  align-items: center;

  & > svg {
    margin: 0 10px 0 0;
  }
}

th:first-child,
td:first-child {
  padding-left: 16px;
}

.vjs-icon-placeholder, .vjs-play-progress, .vjs-volume-level{
  font-family: VideoJS !important;
}
